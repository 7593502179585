@font-face {
    font-family: 'montserrat';
    src: url('/fonts/montserrat-bolditalic-webfont.woff2') format('woff2'),
         url('/fonts/montserrat-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;

}

@font-face {
    font-family: 'montserrat';
    src: url('/fonts/montserrat-italic-webfont.woff2') format('woff2'),
         url('/fonts/montserrat-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'montserrat';
    src: url('/fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('/fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserrat';
    src: url('/fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('/fonts/montserrat-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}
