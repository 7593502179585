@import "colors";
@import "fonts";
@import "breakpoints";


*,
*:after,
*:before {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	font-size: 62.5%;
}

body {
    margin: 0;
  display: flex;
	min-width: 344px;
  color: $fg-color;
	background-color: $bg-color;
	font-family: 'montserrat', monospace;
	font-size: 1.65em;
	font-weight: 400;
	letter-spacing: 0.0625em;
  line-height: 1.6em;
  @media only screen and (min-width : $breakpoint-first) {
    font-size: 1.6em;
    line-height: 1.6em;
  }
	@media only screen and (min-width : $breakpoint-second) {
		font-size: 1.9em;
		line-height: 1.9em;
	}
}

a {
  font-weight: 700;
  color: $darker-fg-color;
  text-decoration: none;
  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

p {
  margin: 1.6rem 0 1.6rem 0;
  a {
    font-weight: 400;
    color: $darker-fg-color;
    text-decoration: underline;
    text-underline-position: under;
    &:focus,
    &:hover {
      color: $link-color;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $darker-fg-color;
  // text-transform: uppercase;
  letter-spacing: 0.0625em;
  margin: 3.2rem 0 1.6rem 0;
}

h1 {
  font-size: 3.2rem;
  line-height: 3.2rem;
  @media only screen and (min-device-width : 320px) and (max-device-width : $breakpoint-first) {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}
h2 {
  font-size: 2.8rem;
  line-height: 2.8rem;
  @media only screen and (min-device-width : 320px) and (max-device-width : $breakpoint-first) {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
}
h3 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  @media only screen and (min-device-width : 320px) and (max-device-width : $breakpoint-first) {
    font-size: 2.0rem;
    line-height: 2.0rem;
  }
}
h4 {
  font-size: 2.2rem;
  line-height: 2.2rem;
  @media only screen and (min-device-width : 320px) and (max-device-width : $breakpoint-first) {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
}
h5 {
  font-size: 2.0rem;
  line-height: 2.0rem;
  @media only screen and (min-device-width : 320px) and (max-device-width : $breakpoint-first) {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}
h6 {
  font-size: 1.4rem;
  line-height: 1.4rem;
}

pre {
  margin: 1.6rem 0 1.0rem 0;
  padding: 1.6rem;
  overflow-x: auto;
}

code {
  background-color: $darker-fg-color;
  color: $bg-color;
  padding: 0.4rem 0.8rem 0.4rem 0.8rem;
}

blockquote {
  border-left: 2px solid $darker-bg-color;
  padding-left: 1.6rem;
  font-style: italic;
}

th, td {
  padding: 1.6rem;
}
table {
  border-collapse: collapse;
}
table td, table th {
  border: 2px solid $darker-fg-color;
}
table tr:first-child th {
  border-top: 0;
}
table tr:last-child td {
  border-bottom: 0;
}
table tr td:first-child,
table tr th:first-child {
  border-left: 0;
}
table tr td:last-child,
table tr th:last-child {
    border-right: 0;
}

img {
  max-width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.container {
  margin: 0 auto;
  max-width: 120.0rem;
  width: 100%;
  padding: 0 1rem;
}

.navigation {
  // height: 6.0rem;
  width: 100%;
	.container {
		display: flex;
		justify-content: space-between;

		.header-avatar {
      flex-grow: 1;

			a {
				text-decoration: none;
			}
			img {
				border-radius: 100%;
			}
			.tiny {
				font-size: 1.5em;
				color: #646464;

				-webkit-text-stroke: 0.5px $darker-fg-color;
				//the following media query and text-shadow simulate -webkit-text-stroke for ie10/ie11
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					text-shadow: -0.5px -0.5px 0 $darker-fg-color,
				    0.5px -0.5px 0 $darker-fg-color,
				    -0.5px 0.5px 0 $darker-fg-color,
				     0.5px 0.5px 0 $darker-fg-color;
					 }


			}
			.tleft {
				position: relative;
				left: 5px;
				bottom: 4px;
			}
			.tright {
				position: relative;
				right: 5px;
				bottom: 4px;
			}
		}
		.navigation-list {
      padding: 0px;
      flex-grow: 2;
      display: flex;
      justify-content: space-around;
		}
	}
  a {
    display: inline;
    font-size: 1.6rem;
    // text-transform: uppercase;
    line-height: 6.0rem;
    letter-spacing: 0.1rem;
    @media only screen and (min-device-width : 320px) and (max-device-width : $breakpoint-first) {
      font-size: 1.4rem;
    }
  }
  ul {
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    li {
      // float: left;
      // flex-grow: 1;
      margin: 0;
      // position: relative;
      a {
        margin-left: 1.0rem;
        margin-right: 1.0rem;
      }
    }
  }
}

.content {
  // flex: 1;
  margin-top: 1.6rem;
  margin-bottom: 3.2rem;
  article {
    header {
      margin-top: 3.2rem;
      margin-bottom: 3.2rem;
      h1, h2 {
        margin: 0;
      }
      h2 {
        margin-top: 1.0rem;
        font-size: 1.8rem;
        color: $fg-color;
        @media only screen and (min-device-width : 320px) and (max-device-width : $breakpoint-first) {
          font-size: 1.6rem;
        }
      }
    }
  }
}

.list {
  ul {
    margin: 3.2rem 0 3.2rem 0;
    list-style: none;
    padding: 0;
    li {

			font-size: 1.4rem;
			margin: 1.6rem 0 1.6rem 0;
      @media only screen and (min-width : $breakpoint-first) {
				font-size: 1.6rem;
      }
      span {
				display: block;
				text-align: left;
        @media only screen and (min-width : $breakpoint-second) {
					display: inline-block;
	        text-align: right;
	        width: 20.0rem;
	        margin-right: 3.0rem;
        }
      }
      // a {
      //   text-transform: uppercase;
      // }
    }
  }
}

.pagination {
  margin-top: 6.0rem;
  text-align: center;
  li {
    display: inline;
    text-align: center;
    span {
      margin: 0;
      text-align: center;
      width: 3.2rem;
    }
    a {
      span {
        margin: 0;
        text-align: center;
        width: 3.2rem;
      }
    }
  }
}

.container.centered {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  .intro-content {
		p {
			margin-top: 8%;
			margin-bottom: 10%;
			@media only screen and (min-width : $breakpoint-second) {
				margin-top: 4%;
				margin-bottom: 5%;
			}
			@media only screen and (min-width : $breakpoint-third) {
				margin-top: 2%;
				margin-bottom: 3%;
			}


		}
	}
  .about {
		.avatar-box {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 50px;
			margin-bottom: 50px;
			img {
				border-radius: 100%;
				width: 23%;
				max-width: 145px;
			}
			.huge {
				font-size: 4.5em;
				color: #646464;
				// text-shadow: 2px 2px 0px black;

				-webkit-text-stroke: 1px $darker-fg-color;
				//the following media query and text-shadow simulate -webkit-text-stroke for ie10/ie11
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					text-shadow:	1px 1px 0 $darker-fg-color,
												1px 1px 0 $darker-fg-color,
												1px 1px 0 $darker-fg-color,
												1px 1px 0 $darker-fg-color;
				}

				@media only screen and (min-width : $breakpoint-first) {
					font-size: 7em;
				}
			}
		}
		// flex-grow: 1;
    text-align: center;
    h1 {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    h2 {
      margin-top: 40px;
      margin-bottom: 40px;
      font-size: 2.4rem;
      @media only screen and (min-width : $breakpoint-first) {
        font-size: 2.0rem;
      }
    }
    ul {
      list-style: none;
      margin: 1.0rem 0 3.0rem 0;
      padding: 0;
      li {
        display: inline-block;
        position: relative;
        a {
          // text-transform: uppercase;
          margin-left: 1.0rem;
          margin-right: 1.0rem;
          font-size: 1.4rem;
          @media only screen and (min-width : $breakpoint-first) {
            font-size: 1.6rem;
          }
					img {
						width: 24px;
					}
        }
      }
    }
  }

  .error {
    text-align: center;
    h1 {
      margin-top: 2.0rem;
      margin-bottom: 0.5rem;
      font-size: 4.6rem;
      @media only screen and (min-device-width : 320px) and (max-device-width : $breakpoint-first) {
        font-size: 3.2rem;
      }
    }
    h2 {
      margin-top: 2.0rem;
      margin-bottom: 3.2rem;
      font-size: 3.2rem;
      @media only screen and (min-device-width : 320px) and (max-device-width : $breakpoint-first) {
        font-size: 2.8rem;
      }
    }
  }
}

.footer {
  // height: 6.0rem;
  width: 100%;
  text-align: center;
  line-height: 6.0rem;
	.footer-nav {
		display: flex;
		justify-content: space-evenly;
		max-width: 120rem;
		margin: 0 auto;
		margin-top: 10%;
		a {
			font-weight: 400;
			text-decoration: underline;
		}
	}
	.copyright {
		font-size: 0.9em;
		line-height: 1em;
		p {
			margin: 1.2rem 0 1rem 0;
		}
	}
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}
